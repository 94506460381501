<template>
  <div class="groupbox">
    <div class="box available"></div>
    <div class="text">Open</div>
    <div class="box restrictions"></div>
    <div class="text">Covid Restrictions</div>
    <div class="box unavailable"></div>
    <div class="text">Closed</div>
  </div>
</template>
<script>
export default {
  name: 'availableComponent',
};
</script>

<style scoped>
.groupbox {
  width: 420px;
  left: 50%;
  margin: auto;
  overflow: hidden;
  padding-bottom: 20px;
}

.box {
  height: 24px;
  width: 24px;
  border-radius: 9px;
}

.text {
  float: left;
  padding-left: 8px;
  padding-right: 16px;
}

.available {
  float: left;
  background-color: deepskyblue;
}

.unavailable {
  background-color: grey;
  float: left;
}

.restrictions {
  background-color: skyblue;
  float: left;
}

.previous {
  float: left;
  margin-top: 169.6px;
  margin-left: 92.4px;
  background-color: #f1f1f1;
  color: black;
  border-radius: 50%;
}
</style>
