<template>
  <div>
    <div id="map"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CoronaMap',
  data() {
    return {
      map: undefined,
      mapLoaded: false,
    };
  },
  created() {
    if (this.$route.query) {
      this.$store.commit('search/setParams', {
        ...this.$route.query,
        maxPrice: parseFloat(this.$route.query.maxPrice),
      });
    }
  },
  mounted() {
    this.initializeMap();
    this.getPriceData();
    this.initializeFilters();
  },
  computed: {
    ...mapGetters([
      'displayCountries',
      'restrictedCountriesFilteredByPrice',
      'openCountriesFilteredByPrice',
    ]),
  },
  methods: {
    ...mapActions([ 'loadPriceData' ]),
    initializeFilters() {
      this.map.on('load', () => {
        this.mapLoaded = true;
        this.map.addLayer({
          id: 'openCountries',
          source: {
            type: 'vector',
            url: 'mapbox://byfrost-articles.74qv0xp0',
          },
          'source-layer': 'ne_10m_admin_0_countries-76t9ly',
          type: 'fill',
          paint: {
            'fill-color': '#00BAF4',
            'fill-opacity': 0.8,
          },
        });
        this.map.addLayer({
          id: 'restrictedCountries',
          source: {
            type: 'vector',
            url: 'mapbox://byfrost-articles.74qv0xp0',
          },
          'source-layer': 'ne_10m_admin_0_countries-76t9ly',
          type: 'fill',
          paint: {
            'fill-color': '#87ceeb',
            'fill-opacity': 0.8,
          },
        });
        this.map.setFilter(
          'openCountries',
          [ 'in', 'ISO_A2' ].concat(this.openCountriesFilteredByPrice),
        );
        this.map.setFilter(
          'restrictedCountries',
          [ 'in', 'ISO_A2' ].concat(this.restrictedCountriesFilteredByPrice),
        );
      });
    },
    initializeMap() {
      this.$mapboxgl.accessToken =
        'pk.eyJ1IjoiYXNhYnJpIiwiYSI6ImNrcHgyZTM1ejA2aWQyb21jMXNoOHVwaGIifQ.B1eQnlHUxuT2rLSDt_ZODQ';
      this.map = new this.$mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/asabri/ckql7ai7y1mu417lfqelcfaw0',
        center: [ 16.35, 45.612 ],
        zoom: 3.0,
      });
    },
    refreshMapLayers() {
      this.map.setFilter(
        'openCountries',
        [ 'in', 'ISO_A2' ].concat(this.openCountriesFilteredByPrice),
      );
      this.map.setFilter(
        'restrictedCountries',
        [ 'in', 'ISO_A2' ].concat(this.restrictedCountriesFilteredByPrice),
      );
    },
  },
  watch: {
    displayCountries() {
      this.mapLoaded && this.refreshMapLayers();
    },
  },
};
</script>

<style scoped>
#map {
  height: 1000px;
}
</style>
