<template>
  <div class="filter-container">
    <v-container>
      <v-row>
        <v-col cols="12" sm="5" md="4" lg="3" class="filter-col">
      <div class="filter">
    <div class="session slider">
      <price-range-slider />
    </div>
    <div class="session slider">
      <p class="interestP">Interest</p>
      <v-container fluid class="new">
        <v-checkbox 
          v-for="(interest, index) in interests" 
          :key="`interest_${index}`"
          :value="interest.value"
          @change="val => setInterest(val, index)"
          :label="interest.label">
        </v-checkbox>
      </v-container>
    </div>
    <button v-bind:class="{'btnInspire':!selectedInterests,'btnInspireclicked':selectedInterests}" :disabled="!selectedInterests" @click="inspireMeHandler">
      <i class="fas fa-search"></i>
      Inspire Me
    </button>
      </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PriceRangeSlider from '../landingPage/landingPageComponents/PriceRangeSlider.vue';

export default {
  components: {
    PriceRangeSlider,
  },
  data() {
    return {
      interests: [
        { label: 'Adventure', value: false },
        { label: 'Sports', value: false },
        { label: 'Relaxing', value: false },
        { label: 'Work', value: false },
        { label: 'History', value: false },
        { label: 'Romantic Getaway', value: false },
        { label: 'Beach', value: false },
        { label: 'Family', value: false },
      ]
    };
  },
  computed: {
    ...mapGetters([ 'experiences' ]),
    selectedInterests() {
      return this.interests
        .map((interest, index) => index)
        .filter(item => this.interests[item].value)
        .map(item => item + 1)
        .join('-');
    },
  },
  methods: {
    ...mapActions([
      'loadExperiences',
    ]),
    async inspireMeHandler() {
      this.$router.push({ name: 'DestinationFeed' });
    },
    setInterest(val, index) {
      this.interests[index].value = val;
    }
  },
  watch: {
    async selectedInterests(val) {
      this.$store.commit('search/setInterests', val);
      val && await this.loadExperiences(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
.filter-container {
  position: absolute;
  top: 0;
  width: 100%;

  .filter-col{
    z-index: 3;
    
    .filter {
      border-radius: 24px;
      box-shadow: 0px 16px 32px rgba(52, 52, 52, 0.1);
      background: white;
      @include respond-to(xs) {
        margin: 0 20px;
      }
    }
  }
}

.session {
  margin-bottom: 12px;
  text-align: left;
  border-radius: 12px;
  margin-top: 24px;
}

.container {
  padding: 0px;
}

.new {
  color: #d62a53 !important;
}

.mainP {
  padding-top: 0;
}

.interestP {
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  color: #000000;
  padding-top: 0px;
}

.p1 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #595959;
  padding-top: 6px;
  /* float: right; */
}

.fa {
  margin-right: 9px;
}

.slider {
  padding-bottom: 22px;
  padding-left: 24px;
  border-bottom: 1px solid #f5f5f5;
  font-weight: bold;
}

/* Dropdown Button */
.dropbtn {
  background-color: transparent;
  padding: 16px 28px 16px 0px;
  font-size: 14px;
  border: none;
  color: #000000;
  font-weight: bold;
}

.btnInspire {
  background-color: #c4c4c4;
  border-radius: 36px;
  width: 238px;
  height: 54px;
  color: white;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 12px;
  border-color: transparent;
  text-align: center;
  font-family: "RedHatDisplayBold";
}

.btnInspireclicked {
  background-color: #d62a53;
  border-radius: 36px;
  width: 238px;
  height: 54px;
  color: white;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 12px;
  border-color: transparent;
  text-align: center;
  font-family: "RedHatDisplayBold";
}

.fa-search {
  margin-right: 9px;
}

.new {
  font-family: "RedHatDisplayMedium";
}

.v-input__slot {
  margin-bottom: 0px !important;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}

.theme--light.v-label {
  margin-left: 8px !important;
}
</style>
