<template>
  <div class="track-container">
    <div class="Pricetitle">
      Price Range<span> EUR {{ maxPrice }}</span>
    </div>
    <div class="priceSlider">
      <input
        class="range-slider__range"
        type="range"
        min="0"
        max="750"
        v-model="maxPrice"
        step="1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PriceRangeSlider',
  computed: {
    maxPrice: {
      get() {
        return this.$store.state.search.maxPrice;
      },
      set(value) {
        this.$store.commit('search/setMaxPrice', value);
      },
    }
  }
};
</script>

<style scoped>
.Pricetitle {
  padding-bottom: 16px;
  font-family: 'RedHatDisplayBold';
  font-size: 20px;
  line-height: 14px;
  letter-spacing: -2%;
}
.Pricetitle span {
  float: right;
  font-size: 16px;
  font-family: "RedHatDisplayMedium";
  margin-right: 23px;
  margin-top: 4px;
  line-height: 12px;
}

.range-slider__range {
  -webkit-appearance: none;
  width: calc(100%);
  height: 6.5px;
  border-radius: 20px;
  background: #dddddd;
  outline: none;
  padding: 0;
  margin: 0;
}
.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border-color: #d62a53;
  background: white;
  border-style: solid;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}
.range-slider__range::-webkit-slider-thumb:hover {
  border-color: #d62a53;
  background: white;
  border-style: solid;
}
.range-slider__range:active::-webkit-slider-thumb {
  border-color: #d62a53;
   background: white;
   border-style: solid;
}
.range-slider__range::-moz-range-thumb {
  width: 26px;
  height: 26px;
  border: 0;
  border-radius: 50%;
   border-color: #d62a53;
  background: white;
  border-style: solid;
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}
.range-slider__range::-moz-range-thumb:hover {
   border-color: #d62a53;
  background: white;
  border-style: solid;
  
}
.range-slider__range:active::-moz-range-thumb {
   border-color: #d62a53;
  background: white;
  border-style: solid;
}
/* .range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px #d62a53;
} */

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 60px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #d62a53;
  padding: 5px 10px;
  margin-left: 8px;
}
.range-slider__value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #d62a53;
  border-bottom: 7px solid transparent;
  content: "";
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}
</style>
