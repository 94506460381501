<template>
  <div v-if="loaded" class="main-content-container">
    <v-container class="position-relative">
      <div class="back-shevron"><v-btn @click="$router.push({name: 'LandingPage'})" fab small><v-icon>mdi-chevron-left</v-icon></v-btn></div>
      <v-row justify="center">
        <v-col md="8" xl="6">
          <h2 class="text-center page-header">You are eligible to travel to the following countries</h2>
          <h3 class="text-center page-subheader grayMain--text mt-4 mb-6">Set your preferences and let us inspire you. Bon Voyage!</h3>
          <available-container />
        </v-col>
      </v-row>
    </v-container>
    <div class="position-relative">
          <map-filter />
          <div class="map-container">
            <corona-map />
          </div>
      </div>
  </div>
</template>
<script>
import AvailableContainer from './AvailableContainer.vue';
import MapFilter from './MapFilter.vue';
import CoronaMap from './CoronaMap.vue';

export default {
  components: {
    AvailableContainer,
    MapFilter,
    CoronaMap,
  },
  data: () => ({
    loaded: false,
  }),
  async mounted() {
    const { city, country, fromDate, toDate } = this.$store.state.search;

    if (!city || !country || !fromDate || !toDate) {
      await this.$router.push({ name: 'LandingPage' });
      return;
    }

    this.loaded = true;
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
.page-header {
  font-size: 50px;
  line-height: 55px;
  @include respond-to(xs) {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 22px;
    line-height: 27px;
  }
}
.page-subheader {
font-size: 25px;
line-height: 24px;
letter-spacing: -0.02em;
  @include respond-to(xs) {
    font-size: 14px;
    line-height: 14px;
  }
}

.map-container{
  @include respond-to(xs) {
    padding-top: 400px;
  }
}
</style>
